import _export from "../internals/export";
import _descriptors from "../internals/descriptors";
import _global3 from "../internals/global";
import _functionUncurryThis from "../internals/function-uncurry-this";
import _hasOwnProperty from "../internals/has-own-property";
import _isCallable from "../internals/is-callable";
import _objectIsPrototypeOf from "../internals/object-is-prototype-of";
import _toString from "../internals/to-string";
import _objectDefineProperty from "../internals/object-define-property";
import _copyConstructorProperties from "../internals/copy-constructor-properties";
var $ = _export;
var DESCRIPTORS = _descriptors;
var _global2 = _global3;
var uncurryThis = _functionUncurryThis;
var hasOwn = _hasOwnProperty;
var isCallable = _isCallable;
var isPrototypeOf = _objectIsPrototypeOf;
var toString = _toString;
var defineProperty = _objectDefineProperty.f;
var copyConstructorProperties = _copyConstructorProperties;
var NativeSymbol = _global2.Symbol;
var SymbolPrototype = NativeSymbol && NativeSymbol.prototype;

if (DESCRIPTORS && isCallable(NativeSymbol) && (!("description" in SymbolPrototype) || // Safari 12 bug
NativeSymbol().description !== undefined)) {
  var EmptyStringDescriptionStore = {}; // wrap Symbol constructor for correct work with undefined description

  var SymbolWrapper = function Symbol() {
    var description = arguments.length < 1 || arguments[0] === undefined ? undefined : toString(arguments[0]);
    var result = isPrototypeOf(SymbolPrototype, this) ? new NativeSymbol(description) // in Edge 13, String(Symbol(undefined)) === 'Symbol(undefined)'
    : description === undefined ? NativeSymbol() : NativeSymbol(description);
    if (description === "") EmptyStringDescriptionStore[result] = true;
    return result;
  };

  copyConstructorProperties(SymbolWrapper, NativeSymbol);
  SymbolWrapper.prototype = SymbolPrototype;
  SymbolPrototype.constructor = SymbolWrapper;
  var NATIVE_SYMBOL = String(NativeSymbol("test")) == "Symbol(test)";
  var symbolToString = uncurryThis(SymbolPrototype.toString);
  var symbolValueOf = uncurryThis(SymbolPrototype.valueOf);
  var regexp = /^Symbol\((.*)\)[^)]+$/;
  var replace = uncurryThis("".replace);
  var stringSlice = uncurryThis("".slice);
  defineProperty(SymbolPrototype, "description", {
    configurable: true,
    get: function description() {
      var symbol = symbolValueOf(this);
      var string = symbolToString(symbol);
      if (hasOwn(EmptyStringDescriptionStore, symbol)) return "";
      var desc = NATIVE_SYMBOL ? stringSlice(string, 7, -1) : replace(string, regexp, "$1");
      return desc === "" ? undefined : desc;
    }
  });
  $({
    global: true,
    forced: true
  }, {
    Symbol: SymbolWrapper
  });
}

export default {};